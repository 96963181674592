var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"informationsGeneraux"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',{attrs:{"id":"expoiltationmensuelle"}},[_c('b-card-header',{staticClass:"d-flex justify-content-between"},[_c('h3',[_vm._v("RENSEIGNEMENTS D'EXPLOITATION DU MOIS")])]),_c('hr'),_c('b-card-body',[_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Durrée moyenne de la visite"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({ length: 16 }),expression:"{ length: 16 }"}],attrs:{"type":"number","min":"0","placeholder":"Veuillez saisir le chiffre d'affaire global"},model:{value:(_vm.infos.durreeMoyenneVisite),callback:function ($$v) {_vm.$set(_vm.infos, "durreeMoyenneVisite", _vm._n($$v))},expression:"infos.durreeMoyenneVisite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Chiffre d'affaire global internationaux (FCFA)"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({ length: 16 }),expression:"{ length: 16 }"}],attrs:{"type":"number","min":"0","placeholder":"Veuillez saisir le chiffre d'affaire global nationaux"},model:{value:(_vm.infos.chiffreAffaireGlobalNationaux),callback:function ($$v) {_vm.$set(_vm.infos, "chiffreAffaireGlobalNationaux", _vm._n($$v))},expression:"infos.chiffreAffaireGlobalNationaux"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Chiffre d'affaire global internationaux (FCFA)"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({ length: 16 }),expression:"{ length: 16 }"}],attrs:{"type":"number","min":"0","placeholder":"Veuillez saisir le chiffre d'affaire global nationaux"},model:{value:(_vm.infos.chiffreAffaireGlobalInterNationaux),callback:function ($$v) {_vm.$set(_vm.infos, "chiffreAffaireGlobalInterNationaux", _vm._n($$v))},expression:"infos.chiffreAffaireGlobalInterNationaux"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Chiffre d'affaire global internationaux (FCFA)"}},[_c('div',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(_vm.normalizeNumericValue(_vm.infos.chiffreAffaireGlobalInterNationaux) + _vm.normalizeNumericValue(_vm.infos.chiffreAffaireGlobalNationaux))+" ")])]),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Charges salariales (FCFA)"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({ length: 16 }),expression:"{ length: 16 }"}],attrs:{"type":"number","min":"0","placeholder":"Veuillez saisir le montant des charges salariales "},model:{value:(_vm.infos.chargesSalariales),callback:function ($$v) {_vm.$set(_vm.infos, "chargesSalariales", _vm._n($$v))},expression:"infos.chargesSalariales"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Autres Dépenses (FCFA)*"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({ length: 16 }),expression:"{ length: 16 }"}],attrs:{"type":"number","min":"0","placeholder":"Veuillez saisir le montant des autres dépenses"},model:{value:(_vm.infos.autresDepense),callback:function ($$v) {_vm.$set(_vm.infos, "autresDepense", _vm._n($$v))},expression:"infos.autresDepense"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Fermer ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }